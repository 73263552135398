w2p.controller('LogsCtrl', function($scope,$http, Notification) {

        $scope.date_from = moment().startOf('isoweek').format('YYYY-MM-DD');
        $scope.date_to = moment().format('YYYY-MM-DD');


        $scope.query = function() {
                $http.get('/logs/getAll/?date_from='+$scope.date_from+'&date_to='+$scope.date_to).success(function(data) {
                        $scope.rows =  data.rows;
                        $scope.point_sum = data.point_sum;
                        $scope.currency_sum = data.currency_sum;
                });
        }

        $scope.query();

        $scope.export = function() {
                if($scope.rows && $scope.rows.length > 0)  {
                     window.location.href = '/logs/export/?date_from='+$scope.date_from+'&date_to='+$scope.date_to;
                }
        }
});
