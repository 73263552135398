w2p.controller('CustomerPresentCtrl', function($scope,$http, Notification) {
    var groups = [
        {
            id: null,
            name: Lang.get('jsmessages.select_group')
        }
    ];

    $scope.butDisabled = true;
    $scope.point = 0;

    $http.get('/customerpresent/groups').success(function(data) {
        $scope.groups = groups.concat(data);
    });

    $scope.selectGroup = function() {
        console.log($scope.selected);
    };

    $scope.loadData = function() {
        $http.get('/customerpresent/customers').success(function(data) {
            $scope.customers = data;
        });
    }

    $scope.selectAll = function () {
        for (var i = 0; i < $scope.customers.length; i++) {
            $scope.customers[i].isChecked = $scope.allItemsSelected;
        }
        $scope.chkSelected();
    };

    $scope.selectCustomer = function () {
        for (var i = 0; i < $scope.customers.length; i++) {
            if (!$scope.customers[i].isChecked) {
                $scope.allItemsSelected = false;
                $scope.chkSelected();
                return;
            }
        }
        $scope.allItemsSelected = true;
        $scope.chkSelected();

    };
    $scope.loadData();


    $scope.addPoint = function() {
        var cards = [];
        for (var i = 0; i < $scope.customers.length; i++) {
            if ($scope.customers[i].isChecked) {
                cards.push($scope.customers[i].card_num);
            }
        }
        $http.post('/customerpresent/addPoints', { point: $scope.point, cards: cards} ).success(function(data) {
            console.log(data);
            data.forEach(function(r) {
                if(r.tx_succ=='ok') {
                    Notification.success({message: Lang.get('jsmessages.succ_gifting'), positionY: 'bottom', positionX: 'right'});
                } else {
                    Notification.error({message: Lang.get('jsmessages.unsucc_gifting'), positionY: 'bottom', positionX: 'right'});
                }
                $('#presentModal').modal('hide');
                $scope.loadData();
            });
        });

    }


    $scope.chkSelected = function() {
        var x = 0;
        for (var i = 0; i < $scope.customers.length; i++) {
            if ($scope.customers[i].isChecked) x++
        }
        if (x>0) {
          $scope.butDisabled = false;
        } else {
            $scope.butDisabled = true;
        }
    }
});