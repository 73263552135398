w2p.controller('AdminMessagesCtrl', function($scope,$http) {

   $scope.data = {
       action: 'group'
   };


   $(".select2").prop("disabled", true);


});