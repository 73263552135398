w2p.directive('bsPopover', function() {
    return function(scope, element, attrs, http) {
        var $el = element.find("[rel=popover]")
        if(!scope.card.is_gift) {
            $el.popover({
                placement: 'right',
                html: 'true',
                trigger: 'hover',
                content: function(e) {
                    var ret = '';
                    for(i in tx_slots) {
                        if(scope.card.balances[i]) {
                            ret+=tx_slots[i]+": <strong>"+scope.card.balances[i]+'</strong><br>';
                        }
                    }
                    return ret;

                }
            });
        }
    };
});

w2p.directive('autoFocus', function($timeout) {
    return {
        restrict: 'AC',
        link: function(_scope, _element) {
            $timeout(function(){
                _element[0].focus();
            }, 0);
        }
    };
});




w2p.controller('TransCtrl', function($scope,$http, $interval, Notification) {
    $scope.succ = 0;


    $scope.slots = [];
    for(i in tx_slots) {
        $scope.slots.push(
            {
                id: i,
                name: tx_slots[i]
            }
        )
    }

    $scope.default_slot = tx_default_slot.toString();

    $scope.getBalance = function() {
        $http.get("/card/balance/"+$scope.default_slot+"/"+$scope.selected.card_id).success(function(data) {
            $scope.act_balance = parseInt(data);
            $scope.slot_balance_text = data;
        });
    }




    $http.get("/settings/ao/get").then(function(ret){
        if(ret.data.values) {
            var v = JSON.parse(ret.data.values);
            $scope.ao_settings = v;
        } else {
            $scope.ao_settings = { limit: 0 };
        }
    });

    $http.get("/settings/tx/get").then(function(ret){
        $scope.auth_user = {
            is_operator: ret.data.is_operator,
            change_slot: ret.data.operator_change_slot
        }
        if(ret.data.values) {
            var v = JSON.parse(ret.data.values);
            $scope.txSettings = v;
        } else {
            $scope.txSettings = { ccam: 'off', stete: 'off'}
        }
    });



    $scope.onSuccess = function(data) {
        if($scope.succ == 0) {
            $scope.succ = 1;
            document.getElementById('camSound').play();
            $('#scanModal').modal('hide');
            var decoded = data;

            if(!$scope.checkCoupon(decoded)) {
                $scope.srcword = decoded;
                var src = ($scope.srcword) ? $scope.srcword : '';
                $http.get('/card/all?srcword='+src ).success(function(data) {
                    $scope.cards = data;
                    setTimeout(function() {
                        if($("td.card_"+decoded).length) {
            //                $("td.card_"+decoded).click();
                        } else {

            //if($scope.checkCoupon(decoded)) {
                            $http.get("/card/checkBalance/"+decoded).then(function(ret) {
                                if(ret.found) {
                                    if(ret.other_company) {
                                        Notification.error({message: Lang.get('jsmessages.card_found_other_company'), positionY: 'bottom', positionX: 'right'});
                                    }
                                } else {
                                    if(!$scope.selected_coupon) {
                                        if(window.confirm(Lang.get('jsmessages.card_not_found_message'))) {
                                            window.location.href='/customers/create?id='+decoded;
                                        }
                                    }
                                }
                            });
			//}

                            //Notification.error({message: 'Ez a kártyaszám nem létezik ebben a csoportban <strong>('+card.card_id+')</strong> jelenleg letiltva!', positionY: 'bottom', positionX: 'right'});
                        }
                        //$scope.srcword='';
                    },500);
                });
            }
        }
    };
    $scope.resetSucc = function() {
        $scope.succ = 0;
    }
    $scope.onError = function(error) {
        console.log(error);
    };
    $scope.onVideoError = function(error) {
        console.log(error);
    };


    $scope.checkCoupon = function(src) {
        $http.get('/coupons/search?srcword='+src).success(function(data) {
            if(data.length == 1) {
                $scope.selected_coupon = data[0];
                $scope.srcword='';
                $('#cpModal').modal('show');
                return true;
            } else {
                $http.get('/card/all?srcword='+src).success(function(data) {
                    if(data.length == 1) {
                        var card = data[0];
                        $("#txModal").modal('show');
                        $scope.selectRow(card);
                    }
                    $scope.cards = data;
                });
                return false;
            }
        });
    }

    $scope.loadData = function() {
        var src = ($scope.srcword) ? $scope.srcword : '';
        if(src.length > 0) {
            $scope.checkCoupon(src);
        } else {
            $http.get('/card/all?srcword='+src ).success(function(data) {
                $scope.cards = data;
            });
        }
    }


    $scope.addCoupon = function() {
        $http.post("/coupons/use", $scope.selected_coupon).then(function(ret) {
            console.log(ret);
            if(ret.data.status == 'OK') {
                Notification.success({message: Lang.get('jsmessages.tx_coupon_added'), positionY: 'bottom', positionX: 'right'});
                $('#cpModal').modal('hide');
            }
            if(ret.data.status == 'FAILED') {
                Notification.error({message: Lang.get('jsmessages.tx_coupon_expired'), positionY: 'bottom', positionX: 'right'});
                $('#cpModal').modal('hide');
            }
        });
    }

    $scope.checkSearch = function() {
        if($scope.srcword.length > 3  || $scope.srcword == '' ) {
            $scope.loadData();
        }
    }

    refresh = $interval(function (index) {
        console.log('refreshed');
        $scope.loadData();
    }, 30000);


    $scope.loadData();

    $scope.fillPointValue = function(n) {
        $scope.tx.point = n;
        $scope.addTxDis = false;
    }
    $scope.fillHufValue = function(n) {
        $scope.tx.huf = n;
        $scope.addTxDis = false;
    }

    $scope.selectRow = function(card) {
        $scope.tx = {};
        console.log(card);
	setTimeout(function() {
        	$("input.afinp:visible").focus();
	},1000);

        $http.get('/settings/tx/get').success(function(data) {
            var s = $scope.default_slot ? $scope.default_slot : tx_default_slot;
            $http.get("/card/balance/"+s+"/"+card.card_id).success(function(data) {
                $scope.act_balance = parseInt(data);
                $scope.slot_balance_text = data;
            });

            $http.get('/me').then(function(ret){
                var user = ret.data;
                console.log(user);

                console.log(moment().diff(user.last_tx_date.date,'hours'));
                console.log(user.operator_time_limit);
                if(parseInt(user.operator_time_limit) && moment().diff(user.last_tx_date.date,'hours') < user.operator_time_limit) {
                    $scope.operator_time_limit = true;
                } else {
                    $scope.operator_time_limit = false;
                }
            });


            if(card.user && card.user.has_birthday) {
                Notification.success({message: Lang.get('jsmessages.tx_birthday_message',{name : card.full_name}), positionY: 'bottom', positionX: 'right'});
            }
            if(card.is_expired) {
                $('#txModal').hide();
                Notification.error({message: Lang.get('jsmessages.tx_card_expired', { card_id: card.card_id } ), positionY: 'bottom', positionX: 'right'});
            }
            if(!card.is_enabled) {
                $('#txModal').hide();
                Notification.error({message: Lang.get('jsmessages.tx_card_disabled', { card_id: card.card_id } ), positionY: 'bottom', positionX: 'right'});
            }


            //$scope.tx = { mode: (card.user_id == 0) ? 'subPoint' : 'addHuf' };
            //setTimeout(function() {
            //    $scope.getBalance();
            //},200);

            if(card.user_id == 0) {
                $scope.tx = { mode:  'subPoint' };
            } else {
                if(card.user.group.transaction_type == 0) {
                    $scope.tx = { mode:  'addPoint',  point: parseInt(JSON.parse(card.user.group.transaction_values).point) };
                    $scope.addTxDis = false;
                } else {
                    $scope.tx = { mode:  'addHuf' };
                }
            }

            $scope.selected = card;
        });

    }

    $scope.setMode = function(m) {
        $scope.tx.mode=m;
	console.log('hejj');
	setTimeout(function() {
        	$("input.afinp:visible").focus();
	},1000);
    }

    $scope.addTxDis = true;

    $scope.productKeyup = function()
    {
        sum = 0;
        $.each($scope.tx.values, function(i,o) {
            sum+=parseInt(o);
        })
        if(sum>0) {
            $scope.addTxDis = false;
        } else {
            $scope.addTxDis = true;
        }
    }

    $scope.hufKeyup = function() {
        if(parseInt($scope.tx.huf)>0) {
            $scope.addTxDis = false;
        } else {
            $scope.addTxDis = true;
        }
    }
    $scope.pointKeyup = function() {
        if(parseInt($scope.tx.point)>0) {
            $scope.addTxDis = false;
        } else {
            $scope.addTxDis = true;
        }
        if($scope.tx.mode == 'subPoint') {
            if($scope.tx.point > $scope.act_balance && $scope.tx.point > 0) {
                $scope.addTxDis = true;
            } else {
                $scope.addTxDis = false;
            }
        }

    }

    //$scope.tx.mode = 'addHuf';
    $scope.setMode = function(mode) {
        switch(mode) {
            case 'addPoint': { $scope.tx.mode = 'addPoint'} break;
            case 'subPoint': { $scope.tx.mode = 'subPoint'} break;
            case 'addHuf': { $scope.tx.mode = 'addHuf'} break;
            case 'addProduct': { $scope.tx.mode = 'addProduct'} break;
            case 'history': { $scope.tx.mode = 'history'} break;
        }
	setTimeout(function() {
	   $("input.afinp:visible").focus();
	},1000);
    }

    $scope.prodimgShow =  function(fn) {
        console.log(fn);
        $(".prod_preview").prop('src','/product/image/'+fn)
        $(".prod_preview").show();
    }

    $scope.prodimgHide =  function() {
        $(".prod_preview").hide();
    }


    $scope.getProducts = function()
    {
        var group_id = $scope.selected.user.group_id;
        $http.get('/transaction/products/'+group_id).success(function(response) {
            $scope.products = response;
        });
    }

    $scope.getHistory = function()
    {
        var card_id = $scope.selected.card_id;
        $http.get('/card/history/'+card_id).success(function(response) {
            console.log(response);
            $scope.history = response;
            //$("#history .scroll-body").slimScroll({height: '250px'})
            // http://plnkr.co/edit/IHnFAiVh0gfcmBdy05Mt?p=preview
        });
    }


    $scope.addTx = function()
    {
        console.log($scope.tx);
        $scope.tx.slot = $scope.default_slot;

        $scope.succ = 0;
        $scope.tx.card_id = $scope.selected.card_id;
        console.log($scope.tx);
        $('#txModal').modal('hide');
	$scope.srcword = '';
        if($scope.tx.mode=='subPoint') {
            $http.post("/transaction/add", $scope.tx).then(function(ret) {
                Notification.success({message: Lang.get('jsmessages.tx_subpoint_message',{ balance: ret.data.balance }), positionY: 'bottom', positionX: 'right'});
    	        $scope.loadData();
                if($scope.txSettings.ccam=='on') {
                    $scope.succ = 0;
                    $('#scanModal').modal('show');
                }
            });

        }
        else if ($scope.tx.mode=='addProduct') {
            $http.post("/transaction/add", $scope.tx).then(function(ret) {
                Notification.success({message: Lang.get('jsmessages.tx_addproduct_message', { balance:ret.data.balance } ), positionY: 'bottom', positionX: 'right'});
                $scope.loadData();
                if($scope.txSettings.ccam=='on') {
                    $scope.succ = 0;
                    $('#scanModal').modal('show');
                }

            });
        } else {
            if($scope.tx.mode=="addPoint" && $scope.pt_limit > 0  && $scope.tx.point > $scope.pt_limit) {
                Notification.error({message: Lang.get('jsmessages.tx_op_pt_limit'), positionY: 'bottom', positionX: 'right'});
            }
            else if($scope.tx.mode=="addHuf" && $scope.ft_limit > 0 && $scope.tx.huf > $scope.ft_limit) {
                Notification.error({message: Lang.get('jsmessages.tx_op_ft_limit'), positionY: 'bottom', positionX: 'right'});
            }

            else {
                $http.post("/transaction/add", $scope.tx).then(function(ret) {
                    Notification.success({message: Lang.get('jsmessages.tx_addpoint_message',{ balance:ret.data.balance} ), positionY: 'bottom', positionX: 'right'});
                    if(parseInt(ret.data.balance) >= parseInt($scope.ao_settings.limit) && parseInt($scope.ao_settings.limit) > 0) {
                                bootbox.dialog({
                                    //message: "<strong>"+$scope.selected.user.name+"</strong> éppen elérte a megadott ("+$scope.ao_settings.limit+") limit-et. Kezdeményezzük a termékbeváltást?",
                                    message: Lang.get('jsmessages.tx_limit_exceeded', { name: $scope.selected.user.name,  limit: $scope.ao_settings.limit }),
                                    title: Lang.get('jsmessages.tx_limit_title'),
                                    buttons: {
                                        redeem: {
                                            label: "Termékbeváltás",
                                            className: "btn-primary",
                                            callback: function() {
                                                var data = { product_id: $scope.ao_settings.product_id, card_id: $scope.selected.card_id};
                                                $http.post("/transaction/addProduct", data).then(function(ret) {
                                                    if(ret.statusText=='OK') {
                                                        Notification.success({message: Lang.get('jsmessages.tx_addproduct_message', { balance:ret.data.balance } ), positionY: 'bottom', positionX: 'right'});
                                                        $scope.loadData();
							$("#srcinput").focus();

                                                        if($scope.txSettings.ccam=='on') {
                                                            //$("#sscan").trigger("click");
                                                        }
                                                    }
                                                });
                                            }
                                        },
                                        cancel: {
                                            label: Lang.get('misc.cancel'),
                                            className: "btn-default",
                                            callback: function() {
                                                if($scope.txSettings.ccam=='on') {
                                                    $scope.succ = 0;
                                                    $('#scanModal').modal('show');
                                                }
                                            }
                                        }
                                    }
                                });
                    } else {
                    }
                    if($scope.txSettings.ccam=='on') {
                        $scope.succ = 0;
                        $('#scanModal').modal('show');
                    }
                    $scope.loadData();
		    $("#srcinput").focus();
                });
            }

        }
    }
});