w2p.controller('CustmsgCtrl', function($scope,$http) {

    //$scope.messages = [];
    //$http.get("/customermessages/getall").success(function(ret) {
    //   $scope.messages = ret;
    //});
    //
    //$scope.getMsg = function(id)
    //{
    //    $http.get("/customermessages/getone/"+id).success(function(ret) {
    //        alert(';gec');
    //        console.log(ret);
    //        $scope.message = ret;
    //    });
    //}
    //
});