w2p.controller('OrderSettingsCtrl', function($scope,$http, Notification) {
    $http.get('/settings/order/get').success(function(data) {
        $scope.data = data.data;
    });
    $scope.save = function()
    {
        $http.post("/settings/order/save", { values: JSON.stringify($scope.data) } ).success(function(data) {
            Notification.success({message: 'Sikeres mentés', positionY: 'bottom', positionX: 'right'});
        });
    }
});