w2p.controller('CardexpireCtrl', function($scope,$http, Notification) {
    var groups = [
        {
            id: null,
            name: Lang.get('jsmessages.select_group')
        }
    ];

    $scope.data = {
        custexpdate: false,
        action: 'reset'
    }
    $http.get('/settings/groups').success(function(data) {
        $scope.groups = groups.concat(data);
    });


    $scope.selectGroup = function() {
        var group_id = $scope.group_id;
//        $scope.data = { custexpdate: false, action: "reset" };
        $scope.data = {};
        $http.get("/settings/cardexpire/"+group_id).success(function(data) {
            $scope.data = data.data;
        });

    }

    $scope.save = function()
    {
        $http.post("/settings/cardexpire/save", { values: JSON.stringify($scope.data), group_id: $scope.group_id}).success(function(data) {
            if(data.status=="ok") {
                Notification.success({message: Lang.get('jsmessages.save_succ'), positionY: 'bottom', positionX: 'right'});
            } else {
                Notification.error({message: Lang.get('jsmessages.save_unsucc'), positionY: 'bottom', positionX: 'right'});
            }
        });
    }




});