w2p.controller('OrderCtrl', function($scope,$http, Notification) {

    //$scope.orderitems.forEach(function(order) {
    //    console.log(order);
    //});

    $scope.balance = $("#balance").val();

    $.assocArraySize = function(obj) {
        // http://stackoverflow.com/a/6700/11236
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    $scope.cart = {};

    $scope.cartlen = 0;

    $scope.selectSlot = function() {
            console.log($scope.slot);
    }



    $scope.submit = function() {
        $http.post("/order/submit", $scope.cart).then(function(ret) {
            if(ret.statusText=='OK') {
                Notification.success({message: Lang.get('jsmessages.order_sent'), positionY: 'bottom', positionX: 'right'});
		setTimeout(function() {
		    window.location.reload();
		},1000);
            } else {
                Notification.error({message: Lang.get('jsmessages.order_not_sent'), positionY: 'bottom', positionX: 'right'});
            }
            $scope.cart = {};
            $scope.cartlen = Object.keys($scope.cart).length;
        });
    }


    $scope.act_balance = 0;
    $scope.calc_sum = function() {
        var r = 0;
        var cart = $scope.cart;
        for(var i in cart) {
            r = r+cart[i].price;
        }
        $scope.act_balance=r;
        $("#jbalance").html(parseInt($scope.balance - r));
    }

    $scope.add = function(product_id) {
        var quantity = $scope.orderitems[product_id];
        if(parseInt(quantity) > 0) {
            $scope.cart[parseInt(product_id)] = {
                quantity: quantity,
                product_name: $scope.productnames[product_id],
                price: $scope.productprices[product_id] * quantity,
                product_id: product_id
            };
        }
        $scope.cartlen = Object.keys($scope.cart).length;
        $scope.calc_sum();
    }

    $scope.remove = function(product_id) {
        delete $scope.cart[product_id];
        $scope.cartlen = Object.keys($scope.cart).length;
        $scope.calc_sum();
    }


});