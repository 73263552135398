w2p.controller('UsersCtrl', function($scope,$http, Notification) {


    $scope.sendPw = function(user_id) {
        $http.post('/users/sendPw', { user_id: user_id }).success(function(response) {
            if(response.success) {
                Notification.success({message: Lang.get('jsmessages.pwsend_succ'), positionY: 'bottom', positionX: 'right'});
            }
        });
    }
});