w2p.controller('BirthdayCtrl', function($scope,$http, Notification) {
    var groups = [
        {
            id: null,
            name: Lang.get('jsmessages.select_group')
        }
    ];
    $scope.selectGroup = function() {
        $scope.data = { autopresent: false, sendoperator: false, customeremail: false, point: 0 };
        $http.get("/settings/birthday/"+$scope.group_id).success(function(data) {
           $scope.data = data.data;
        });
    }

    $scope.save = function()
    {
        $http.post("/settings/birthday/save", { values: JSON.stringify($scope.data), group_id: $scope.group_id}).success(function(data) {
            Notification.success({message: Lang.get('jsmessages.save_succ'), positionY: 'bottom', positionX: 'right'});
        });
    }



    $http.get('/settings/groups').success(function(data) {
        $scope.groups = groups.concat(data);
    });


});