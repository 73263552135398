w2p.controller('ProductsCtrl', function($scope) {
    $scope.setFile = function(element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function(event) {
            $scope.image_source = event.target.result
            $scope.$apply()

        }
        reader.readAsDataURL(element.files[0]);
    }
});