w2p.controller('CouponsCtrl', function($scope,$http, Notification) {


    $http.get('groups/all').success(function(data) {
        $scope.groups = data;
    });

    $scope.select = function(id) {
        $scope.send = { coupon_id: id };
    }


    $scope.save = function()
    {
        $http.post("/coupons/send", $scope.send ).success(function(data) {
            if(data.status=='ok') {
                $('#modal-send.modal.in').modal('hide')
                Notification.success({message: trans('jsmessages.send_succ'), positionY: 'bottom', positionX: 'right'});
            }
            if(data.status=='card_not_found') {
                Notification.warning({message: trans('jsmessages.card_not_found'), positionY: 'bottom', positionX: 'right'});
            }
        });
    }
});