w2p.controller('TxSettingsCtrl', function($scope,$http, Notification) {

    $http.get('/settings/tx/get').success(function(data) {
        $scope.data = data.data;
    });
    $scope.save = function()
    {
        $http.post("/settings/tx/save", { values: JSON.stringify($scope.data) } ).success(function(data) {
            Notification.success({message: Lang.get('jsmessages.save_succ'), positionY: 'bottom', positionX: 'right'});
        });
    }
});