w2p.controller('SlotSettingsCtrl', function($scope,$http, Notification) {


    if($("#slot_settings").val()) {
        $scope.slot_settings = JSON.parse($("#slot_settings").val());
    }

    $scope.submit = function() {
        $("#slot_settings").val(JSON.stringify($scope.slot_settings));
    }

});