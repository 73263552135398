w2p.controller('CustomersCtrl', function($scope,$http, Notification) {

    $scope.succ = 0;

    $('[data-toggle="popover"]').popover({
        placement : 'right',
        trigger : 'hover',
        html: true,
        content: function() {
            var balances = $(this).data('balances');
            var slots = $(this).data('slots');
            var ret = '';
            for(i in slots) {
                if(balances[i]) {
                    ret+=slots[i]+": <strong>"+balances[i]+'</strong><br>';
                }
            }
            return ret;
        }
    });


    $scope.sendPw = function(user_id) {
        $http.post('/customers/sendPw', { user_id: user_id }).success(function(response) {
            if(response.success) {
                Notification.success({message: Lang.get('jsmessages.pwsend_succ'), positionY: 'bottom', positionX: 'right'});
            }
        });
    }

    $scope.onSuccess = function(data) {
        if ($scope.succ == 0) {
            $scope.succ = 1;
            document.getElementById('camSound').play();
            $('#scanModal').modal('hide');
            var decoded = data;
            $scope.card_id = decoded;
        }
    }



    $scope.users = [];

    $("select#referal").select2({
        theme: "bootstrap",
        placeholder: Lang.get('jsmessages.select_referal'),
        language: "hu",
        allowClear: true,
     	language: locale,
        ajax: {
            url: "/customers/referals",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                };
            },
            processResults: function (data, params) {
                return {
                    results: data,
                };
            },
            cache: false
        },
        id: 'id',
    	escapeMarkup: function (markup) { return markup; },
        minimumInputLength: 3,
        templateSelection: function (item) { return '<div>'+item.text+'</div>'; },
        templateResult: function (item) { return '<div>'+item.text+' <strong>'+item.card_num+'</strong></div>'; },
    });



    //$scope.bulkSend = function() {
    //    if(window.confirm('Biztosan elküldjük '+$scope.users.length+' vásárlónak?')) {
    //
    //        $scope.users.forEach(function(user_id) {
    //            $http.post('/customers/sendPw', { user_id: user_id }).success(function(response) {
    //                if(response.success) {
    //                    Notification.success({message: 'Sikeres jelszóküldés!', positionY: 'bottom', positionX: 'right'});
    //                }
    //            });
    //        });
    //        //for(var user_id in $scope.users) {
    //        //    console.log(user_id);
    //        //    if(parseInt(user_id) > 0) {
    //        //        console.log(user_id);
    //        //        //$http.post('/customers/sendPw', { user_id: user_id }).success(function(response) {
    //        //        //    if(response.success) {
    //        //        //        Notification.success({message: 'Sikeres jelszóküldés!', positionY: 'bottom', positionX: 'right'});
    //        //        //    }
    //        //        //});
    //        //    }
    //        //}
    //    }
    //}
    //

    $(".form_datetime").datetimepicker({
        autoclose: true,
        fontAwesome: true,
        language:  'hu',
        format: 'yyyy-mm-dd',
        minView: 2
    });

    if(window.location.pathname == '/customers/create') {
        if($("#card_id").val()=='') {
            $("#gen_card_id").click();
        }
    }


});