w2p.controller('GroupsCtrl', ['$scope', function($scope) {

    if($("#group_transaction_values_json").val()) {
        var data = JSON.parse($("#group_transaction_values_json").val());
        $scope.group = { transaction_values: data };
    } else {
        $scope.group = { transaction_values: {} };
    }

    $scope.setStriped  = function(bool) {
        $scope.group.transaction_values.striped = bool;
    }
    $scope.submit = function() {
        $("#group_transaction_values_json").val(JSON.stringify($scope.group.transaction_values));
    }
    $(".groups-form").removeClass('hidden');

}]);
