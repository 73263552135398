w2p.controller('AoSettingsCtrl', function($scope,$http, Notification) {


    $http.get('/settings/ao/get').success(function(data) {
        $scope.data = data.data;
    });

    $http.get('/settings/products').success(function(data) {
        $scope.products = data;
    });

    $scope.save = function()
    {
        $http.post("/settings/ao/save", { values: JSON.stringify($scope.data) } ).success(function(data) {
            Notification.success({message: Lang.get('jsmessages.save_succ'), positionY: 'bottom', positionX: 'right'});
        });
    }
});