w2p.controller('CustomersPageCtrl', function($scope,$http) {
    $scope.setFile = function(element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function(event) {
            $scope.image_source = event.target.result
            $scope.$apply()

        }
        reader.readAsDataURL(element.files[0]);
    }

    $scope.save = function()
    {
        $http.post("/settings/customersPagesave", { values: JSON.stringify($scope.data), group_id: $scope.group_id}).success(function(data) {
            if(data.status=="ok") {
                Notification.success({message: 'Sikeres mentés', positionY: 'bottom', positionX: 'right'});
            } else {
                Notification.error({message: 'Sikertelen mentés', positionY: 'bottom', positionX: 'right'});
            }
        });
    }
});