w2p.controller('AcSettingsCtrl', function($scope,$http, Notification) {


    $http.get('/settings/ac/get').success(function(data) {
        $scope.data = data.data;
    });

    $scope.save = function()
    {
        $http.post("/settings/ac/save", { values: JSON.stringify($scope.data) } ).success(function(data) {
            Notification.success({message: 'Sikeres mentés', positionY: 'bottom', positionX: 'right'});
        });
    }
});