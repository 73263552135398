(function(){
    // common jquery
    $('[data-toggle=confirmation]').confirmation({
        onConfirm: function() {
            $(this).submit();
        }
    });
    $("#gen_card_id").on("click",function() {
        $("#card_id").val(Math.floor(Math.pow(10, 9) + Math.random() * 9 * Math.pow(10, 9)));
    });

    $("#gen_code").on("click",function() {
        var ret = Math.random().toString(36).slice(2).substr(0, 8);
        $("#code").val(ret);
    });




    Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

    $(".form_datetime").datetimepicker({
        autoclose: true,
        fontAwesome: true,
        language:  locale,
        format: 'yyyy-mm-dd',
        minView: 2
    });

    $("select[multiple=multiple]").select2({
        theme: "bootstrap",
        tags: "true",
        //placeholder: Lang.get("jsmessages.select_customers")
    });

    $(".summernote").summernote({
        lang: locale,
        height: 800
    });

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
    
    $("#tblfilter").keyup(function() {
        console.log($(this).val());
        $("#filterabletbl tbody tr:contains('" + $(this).val() + "')").show();
        $("#filterabletbl tbody tr:not(:contains('" + $(this).val() + "'))").hide();
    });
    new Clipboard('.btn-copy');
})();

$(document).ready(function(){
    $('input[type=file]').bootstrapFileInput();
    $('.file-inputs').bootstrapFileInput();
});




var w2p = angular.module('w2pApp', ['ngSanitize','initialValue','ui-notification','qrScanner','angularUtils.directives.dirPagination'])
    .config(function(NotificationProvider) {
        NotificationProvider.setOptions({
            delay: 3000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'right',
            positionY: 'bottom',
        });
    });

w2p.filter('unsafe', function($sce) { return $sce.trustAsHtml; });


w2p.directive('autofocus', ['$timeout',
    function ($timeout) {
        return {
            restrict: 'A',
            link: function ($scope, $element) {
                $timeout(function () {
                    $element[0].focus();
                });
            }
        };
    }
]);


w2p.filter('range', function() {
    return function(input, total) {
        total = parseInt(total);
        for (var i=0; i<total; i++)
            input.push(i);
        return input;
    };
});


w2p.filter("relDate", function() {
    moment.locale(locale);
    return function(date) {
        if(date) {
            return moment(new Date(date)).fromNow();
        } else {
            return '';
        }

    };
});

w2p.filter('htmlToPlaintext', function() {
        return function(text) {
            return angular.element(text).text();
        };
});

w2p.controller('NotifyCtrl', function($scope,Notification) {
    $scope.$watch("msg", function(){
        if($scope.msg!='') {
            Notification.success({message: $scope.msg, positionY: 'bottom', positionX: 'right'});
        }
    });

});


